import React from "react";
import cuaca from '../asset/cuaca.jpeg';
import gif from '../asset/gif.jpeg';
import profile from '../asset/profile.png';
import music from '../asset/music.png';

const Projects = () => {
    return (
        <section id="projects" className="py-0 bg-indigo-800">
            <div className="container mx-auto text-center">
                <h2 className="text-white text-4xl font-bold mb-10">My Project</h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-stone-400 p-4 rounded w-3/4 mx-auto mr-1">
                        <img src={cuaca} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-white text-2xl font-bold mb-2">Web Cuaca</h3>
                        <p className="text-white">
                            Membuat tampilan web untuk mendeteksi cuaca
                        </p>
                    </div>

                    <div className="bg-stone-400 p-4 rounded w-3/4 mx-auto ml-1">
                        <img src={gif} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-white text-2xl font-bold mb-2">Gif Generator</h3>
                        <p className="text-white">
                            Membuat web Gif Generator
                        </p>
                    </div>

                    <div className="bg-stone-400 p-4 rounded w-3/4 mx-auto mr-1">
                        <img src={profile} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-white text-2xl font-bold mb-2">Profile</h3>
                        <p className="text-white">
                            Membuat tampilan profile menggunakan figma
                        </p>
                    </div>

                    <div className="bg-stone-400 p-4 rounded w-3/4 mx-auto ml-1">
                        <img src={music} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-white text-2xl font-bold mb-2">Web Music</h3>
                        <p className="text-white">
                            Membuat tampilan music menggunakan figma
                        </p>
                    </div>
                </div>
                <p className="text-lg text-white mt-8">
                    _______________________________________________________________________________________________________________________________
                </p>
            </div>
        </section>
    )
}


export default Projects;