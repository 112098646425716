import React from "react";

const Contact = () => {
    return (
        <section id="contact" className="py-5 bg-indigo-800">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl font-bold mb-8 text-white">Contact Me</h2>
                <p className="text-xl mb-4 text-white">
                    Feel free to leave me a message
                </p>
                <p className="text-xl mb-4 text-white">
                    gsatriaputra8@gmail.com
                </p>
                <a href="mailto:gsatriaputra8@gmail.com" className="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-6 rounded-lg shadow-md transition duration-300">
                    email
                </a>
                <p className="text-lg text-white mt-8">
                    _______________________________________________________________________________________________________________________________
                </p>
            </div>
        </section>
    )
}

export default Contact;