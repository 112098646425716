import React from "react";

const Footer = () => {
    return (
        <footer className="bg-indigo-800 text-white py-2">
            <div className="text-2xl mb-4">&copy; {new Date().getFullYear()} Gilang Satria Putra. All right reserved</div>
        </footer>
    )
}

export default Footer;