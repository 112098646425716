import React from "react";
import Profile from '../asset/profile.jpeg';
import react from '../asset/react.webp';
import uiux from '../asset/uiux.png';
import nodejs from '../asset/nodejs.png';

const About = () => {
    return (
        <section id="about" className="py-10 bg-indigo-800" >
            <div className="container mx-auto text-center px-6">
                <h2 className="text-4xl font-bold mb-8  text-white">
                    About Me
                </h2>

                <div className="flex flex-col items-center mb-12">
                    <img src={`${Profile}`} alt="Profile Picture" className="w-32 h-32 rounded-full object-cover mb-4 shadow-lg"/>
                    <p className="text-lg text-white mb-2">KIDI Intern</p>
                    <p className="text-xl text-white font-semibold">I’m an software enginner intern in KIDI Telkom Educourse</p>
                <p className="text-lg text-white mt-8">
                    _______________________________________________________________________________________________________________________________
                </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className=" bg-stone-400 p-6 rounded-lg shadow-lg hover:bg-stone-50 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">ReactJs</h3>
                        <img src={`${react}`} alt="Profile Picture" className="w-32 h-32 mx-auto items-center"/>
                        <p className="text-white mb-4">
                            adalah pustaka (library) JavaScript yang digunakan untuk membangun antarmuka pengguna(user interface) yang interaktif,khususnya untuk aplikasi berbasis web
                        </p>
                    </div>

                    <div className="bg-stone-400 p-6 rounded-lg shadow-lg hover:bg-gray-50 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">UI/UX</h3>
                        <img src={`${uiux}`} alt="Profile Picture" className="mx-auto items-center"/>
                        <p className="text-white mb-4">
                            UI adalah twntang bagaimana suatu produk terlihat, sedangkan UX  adalah tentang bagaimana perassan pengguna saat menggunakannya
                        </p>
                    </div>

                    <div className="bg-stone-400 p-6 rounded-lg shadow-lg hover:bg-gray-50 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">NodeJs</h3>
                        <img src={`${nodejs}`} alt="Profile Picture" className=",x-auto items-center"/>
                        <p className="text-white mb-4">
                            memungkinkan pengembang untuk menjalankan kode JavaScript di sisi server, bukan hanya di sisi klien (browser)
                        </p>
                    </div>
                </div>
                <p className="text-lg text-white mt-8">
                    _______________________________________________________________________________________________________________________________
                </p>
            </div>
        </section>
    )
}

export default About;