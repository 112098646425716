import React from "react";
import cover from '../asset/cover.jpeg';

const Hero = () => {
    return (
        <section className="bg-cover bg-center h-screen text-white"
        style={{ backgroundImage: `url(${cover})` }}
        >
            <div className="bg-black bg-opacity-60 h-full flex flex-col justify-center items-center text-center p-6">
                <h2 className="text-5xl font-bold nb-4">Hello, I'm Gilang Satria Putra</h2>
                <p className="text-4xl nb-8 font-bold mt-4">KIDI Intern</p>
                <a href="#projects" className="bg-blue-600 hover:bg-blue-400 mt-8 text-white font-bold py-2 px-6 rounded-2xl shadow-lg transition duration-300">
                    My Project
                </a>
            </div>

        </section>
    )
}

export default Hero;